@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.signup-container{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--font-family);
  margin: 0;
  background: linear-gradient(to bottom, rgb(129, 129, 129), rgb(235, 235, 235));
  


}
.register{
  max-width: 800px;
  width: 100vw;
  display:flex;
  border-radius: 10px;
  /* box-shadow: 1px 3px 10px #e9ecef; */
  background-color: white;
  height: 525px;


}

.marginright h2 {

    margin-right: 14.5rem; /* Adjust the value as needed */
   
}
.register span{
  color: #565656;
  margin-right: 6rem;
}

#form{
  max-width: 320px;
  width: 100vw;
  margin: 2em auto;
  height: 325px;
}

#form >  input, .bt{
  border: 1px solid #e9ecef;
  padding: .9em 1em;
}

#form > input:focus{
  outline: none;
  
}

#form > .bt{
  padding: 0.5rem 1rem;
  color: #fff;
  background: #3e3e3e;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 2rem;
}

.flex{
  display: flex;
  gap: 1em;
  
}

.flex-col{
  flex-direction: column;
 
}

.register .col-1{
  margin: auto;
  padding: 3em 0;
}

.register h2 {

  margin-bottom: 1rem;
}

.register .col-2 img{
  width: 400px;
  height: 100%;
  object-fit: cover;
  align-self: flex-end;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}




.success-checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  animation: checkmarkScale 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
             checkmarkPop 0.6s ease-in-out 0.7s both;
  opacity: 0;
}

.checkmark-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  color: #3e3e3e;
}

@keyframes checkmarkScale {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1.5);
    opacity: 1;
  }
}

@keyframes checkmarkPop {
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}



.error-container {
  height: 21px; /* Fixed height for the error message container */
  overflow: hidden; /* Hide overflowing content */
  transition: height 0.3s ease; /* Smooth transition when showing/hiding the error message */
}

.password-mismatch {
 /* Space between the password input and the error message */
  color: red;
  font-family: var(--font-family);
  font-size: 12px;
  opacity: 0; /* Initially invisible */
}

.visible {
  opacity: 1; /* Make the error message visible */
  height: auto; /* Allow the error message to expand and take space */
}



@media screen and (min-width: 860px)  {
  .register .col-2 img{
    display: initial;
  }
}


.success-message2 h2 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 26px;
  line-height: 49px;
  letter-spacing: -0.02em;
  margin-top: 1rem;

 }