.structuralBC__header {
    display: flex;
    flex-direction: row;
    height: 80vh;
}

.structuralBC__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 60vh;


    margin-right: 5rem;
}

.structuralBC__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 45px;
    line-height: 65px;
    letter-spacing: -0.04em;
}

.structuralBC__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.structuralBC__header-content__input {
    width: 100%;
    margin: 1rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.structuralBC__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.structuralBC__header-content__input button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #3e3e3e;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}



.structuralBC__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;


}

.structuralBC__header-image img {
    width: 45vw; /* Adjust the width value as needed */
    height: auto; 
}

@media screen and (max-width: 1050px) {
    .structuralBC__header {
        flex-direction: column;
    }

    .structuralBC__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .structuralBC__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .structuralBC__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .structuralBC__header-content__people {
        flex-direction: column;
    }

    .structuralBC__header-content__people p {
        margin: 0;
    }

    .structuralBC__header-content__input input,
    .structuralBC__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .structuralBC__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .structuralBC__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .structuralBC__header-content__input input,
    .structuralBC__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}