/* contactpage.css */



.maincontact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-image: url("../../assets/vancouver.jpg");
    background-size: cover;
    background-position: center;
  }
  
  .structuralBC__contact-content {
    background-color: rgba(255, 255, 255,1);
    padding: 1vh;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  

  .structuralBC__contact-content h1 {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 26px;
    line-height: 49px;
    letter-spacing: -0.02em;
    margin-bottom: 1rem;
    margin-top: 1rem;
   
  }


  .email,
  .name,
  .message {
    /* Common styling for email, name, and message */
    border: 1px solid #e9ecef;
    padding: 0.9em 1em;
    outline: none;
    width: 375px;
    margin-bottom: 1em;
   /* Apply the same font-family */
  }
  
  .email,
  .name {
    height: 35px;
  }
  
  .message {
    height: 30vh;
    /* You already have the font-family property applied above */
  }

  
 .btn3 {

    padding: 0.5rem 1rem;
    color: #fff;
    background: #3e3e3e;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 375px;
    

 }


 .info {

    display: flex;
    flex-direction: column;
    align-items: center;

 }  