* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  
  a {
    color: unset;
    text-decoration: none;
  }
  
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh; /* Set a minimum height to ensure the gradient covers the entire viewport */
    margin: 0; /* Remove margin to ensure full coverage */
    background: linear-gradient(to bottom, white, rgb(129, 129, 129));
  }
  
  
  
  /* In your App.css or inline styles */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

  
  /* Rest of your CSS styles */
  
  
  .gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .section__padding {
    padding: 4rem 6rem;
  }
  
  .section__margin {
    margin: 4rem 6rem;
  }
  
  .scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }


  
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }
  