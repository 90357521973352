.Ie h2 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text);
    margin-bottom: 0.5rem;
    margin-top: 1rem;

}

.Ie {
   
    flex-direction: column;
    justify-content: center;
    margin-left: 5rem;
    margin-top: 2rem;
}




.btn {

    width: 200px;
    height: 35px;
    font-size: 15px;
    margin-bottom: .5rem;

}

.btn2 {

    margin-top: 2rem;
    width: 250px;
    height: 35px;
    font-size: 15px;
    margin-bottom: 2rem;

}

.question-container {
    display: flex;
    align-items: center;
  }

.question-container p {

    margin-left: 1rem;
}  


.main {

    display: flex;
    justify-content: Left; /* Distributes items evenly along the main axis */
    
}

.values {

margin-bottom: 1rem;
margin-left: 5rem;

}

.values h2 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    color: black;
    text-align: left;
    margin-top: 1rem;
   
}

.values p {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-text);
    margin-top: 1rem;

}


.seismic {

    display: flex;

}


.seismic p {

    margin-right: 3rem;
}
.save-pdf-button{
    padding: 0.5rem 1rem;
    height: 35px;
    margin-left: 500px;  
    position: absolute;
    top: 332px; 
  }

/* Add this to your CSS stylesheet */
@keyframes buttonClickAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply the animation to the button when it's clicked */
  .save-pdf-button.clicked {
    animation: buttonClickAnimation 0.3s;
  }
  
  